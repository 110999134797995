import { render, staticRenderFns } from "./liveincome.vue?vue&type=template&id=6acd6550&scoped=true&"
import script from "./liveincome.vue?vue&type=script&lang=js&"
export * from "./liveincome.vue?vue&type=script&lang=js&"
import style0 from "./liveincome.vue?vue&type=style&index=0&id=6acd6550&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6acd6550",
  null
  
)

export default component.exports